<template>
  <div class="content-container week-of-mm-manager-wrapper">
    <PageTitle title="Week van de Mediawijsheid manager" class="page-title"></PageTitle>
    <div v-if="!isProduction" class="week-of-mm-data-section">
      <p>
        Op deze pagina is het mogelijk de start datum, eind datum en rangschikking datum van de Week van de Mediawijsheid te veranderen voor testdoeleinden.<br>
        De eind datum moet <i>na</i> de start datum zijn, en de rangschikking datum moet <i>na</i> de eind datum zijn.<br>
        De functionaliteit om deze datums te veranderen is <i>niet</i> beschikbaar op de productie omgeving.<br><br>
        Op de productie omgeving staan deze datums vast op:
      </p>
    </div>

    <ul>
      <li>Startdatum: 7 november 2024 - 08:00</li>
      <li>Einddatum: 15 november 2024 - 15:00</li>
      <li>Rangschikking datum: 18 november 2024 - 13:00</li>
    </ul>

    <div class="explain-week-of-mm-states-section">
      <h2>Toelichting toestanden van het platform</h2>

      <h4>Voordat de week is begonnen:</h4>
      <ul>
        <li>Leraar en professional accounts aanmaken</li>
        <li>School klassen aanmaken en leraren koppelen aan de klas</li>
        <li>Demo missie spelen</li>
        <li>Nog geen bits verdienen</li>
      </ul>

      <h4>Tijdens de week:</h4>
      <ul>
        <li>Bits verdienen</li>
        <li>De 5 week missies spelen</li>
        <li>Bits verdienen met extra games</li>
        <li>Bits verdienen met de media quiz</li>
        <li>Nog geen rangschikking van klassen bekijken</li>
      </ul>

      <h4>Nadat de week is afgelopen, maar voor de rangschikking datum:</h4>
      <ul>
        <li>Geen bits meer verdienen</li>
        <li>Thema missies spelen</li>
        <li>Klasgesprek missies spelen</li>
        <li>Nog geen rangschikking van klassen bekijken</li>
      </ul>

      <h4>Na de rangschikking datum:</h4>
      <ul>
        <li>De rangschikking van klassen bekijken</li>
        <li>Prijzen worden uitgereikt</li>
      </ul>
    </div>

    <div v-if="!isProduction" class="week-data-form">
      <h2>Aanpassen datums van de Week van de Mediawijsheid</h2>
      <DateSelector
        title="Begindatum Week van de Mediawijsheid"
        :start-date="startDate"
        :clearable="false"
        :on-change="onStartDateChanged"
        :include-time-input="true"
      ></DateSelector>
      <DateSelector
        title="Einddatum Week van de Mediawijsheid"
        :start-date="endDate"
        :clearable="false"
        :on-change="onEndDateChanged"
        :include-time-input="true"
      ></DateSelector>
      <DateSelector
        title="Rangschikking datum Week van de Mediawijsheid"
        :start-date="rankingDate"
        :clearable="false"
        :on-change="onRankingDateChange"
        :include-time-input="true"
      ></DateSelector>

      <p></p>

      <div style="margin-top: 32px;">
        <label for="deleteAllMissionProgress" style="font-weight: 700; margin-right: 16px; margin-top: 16px; cursor:pointer; user-select:none;">
          Verwijder all missie progressie, verdiende bits en verdiende jokers
          <input
            id="deleteAllMissionProgress"
            v-model="deleteAllMissionProgress"
            name="deleteAllMissionProgress"
            type="checkbox"
          />
        </label>
      </div>


      <p v-if="formError" class="text-error">{{ formError }}</p>

      <p v-if="deleteAllMissionProgress" style="font-weight: 700; margin-top: 32px; margin-bottom: 32px; font-size: 18px;">
        Let op: bij het opslaan van deze datums wordt alle progressie van alle klassen verwijderd!
      </p>

      <div style="margin-top: 32px;">
        <button class="pink-button tooltip" @click="saveWeekData">
          Sla week data op
        </button>
      </div>
    </div>
  </div>

</template>

<script>

import {
  // computed,
  ref,
} from '@vue/reactivity'
import PageTitle from '@/components/elements/texts/PageTitle'
import ApiHandler from '@/utils/handlers/ApiHandler'
import DateSelector from '@/components/elements/inputFields/DateSelector'
import { formatISO9075 } from 'date-fns'

export default {
  name: 'WeekOfMMManger',
  components: {
    DateSelector,
    PageTitle,
  },
  setup() {
    const formError = ref('')

    // const isProduction = true
    const isProduction = process.env.VUE_APP_IS_PRODUCTION === 'production'

    const startDate = ref(new Date(2022, 10, 1))
    const endDate = ref(new Date(2022, 10, 6))
    const rankingDate = ref(new Date(2022, 10, 11))

    const deleteAllMissionProgress = ref(false)

    ApiHandler.get('global-settings/get-week-data')
      .then((weekData) => {
        console.log('globalWeekData API', weekData)
        startDate.value = new Date(weekData.data.startDate)
        endDate.value = new Date(weekData.data.endDate)
        rankingDate.value = new Date(weekData.data.rankingDate)
      })


    function onStartDateChanged(date) {
      startDate.value = date
    }
    function onEndDateChanged(date) {
      endDate.value = date
    }
    function onRankingDateChange(date) {
      rankingDate.value = date
    }

    function saveWeekData() {
      formError.value = ''
      if (isProduction) return

      const startDateStringSql = formatISO9075(startDate.value)
      const endDateStringSql = formatISO9075(endDate.value)
      const rankingDateStringSql = formatISO9075(rankingDate.value)

      // console.log('startDateStringSql', startDateStringSql)
      // console.log('endDateStringSql', endDateStringSql)
      // console.log('rankingDateStringSql', rankingDateStringSql)

      // validate dates
      if (startDateStringSql > endDateStringSql) {
        formError.value = 'De startdatum moet voor de einddatum zijn'
        return false
      }
      if (endDateStringSql > rankingDateStringSql) {
        formError.value = 'De rangschikking datum moet voor de einddatum zijn'
        return false
      }

      const formData = new FormData()
      formData.append('startDate', startDateStringSql)
      formData.append('endDate', endDateStringSql)
      formData.append('rankingDate', rankingDateStringSql)
      formData.append('deleteAllMissionProgress', deleteAllMissionProgress.value ? '1' : '0')
      ApiHandler.post('cms-global-settings/set-week-of-mm-data', formData)
        .then(() => {
          window.location.reload()
        })
        .catch((error) => {
          window.alert('De einddatum moet na de startdatum zijn, en de rangschikking datum moet na de einddatum zijn')
        })
    }

    return {
      isProduction,
      // globalWeekData,

      // dates
      startDate,
      endDate,
      rankingDate,

      deleteAllMissionProgress,

      formError,

      // date edit functions
      onStartDateChanged,
      onEndDateChanged,
      onRankingDateChange,
      saveWeekData,

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.week-of-mm-manager-wrapper {
  p {
    margin-bottom: 16px;
  }
  p, li {
    font-size: 14px;
  }
  h3, h4 {
    margin-bottom: 16px;
  }
  h2 {
    margin-top: 32px;
    margin-bottom: 24px;
  }
}

</style>
